enum EFrameMessages {
  MAX_VIDEO_SIZE = 'maxVideoSize',
  ACTIVE_DEVICES = 'activeDevices',
  TOGGLE_PROVIDER_AUDIO = 'toggleProviderAudio',
  PROVIDER_AUDIO_STATUS = 'providerAudioStatus',
  GOT_REMOTE_STREAM = 'gotRemoteStream',
  UPDATE_PROVIDED_STREAM = 'updateProvidedStream',
  SET_FRAME_MODE = 'setFrameMode',
}

export default EFrameMessages;
export { EFrameMessages };
