/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

class Deferred {
  #resolve!: Function;
  #reject!: Function;
  isDone = false;
  promise!: Promise<any>;

  constructor() {
    this.promise = new Promise((res, rej) => {
      this.#resolve = res;
      this.#reject = rej;
    });
  }

  public resolve(res: any): void {
    this.isDone = true;
    this.#resolve(res);
  }

  public reject(e: any): void {
    this.isDone = true;
    this.#reject(e);
  }
}

type DelayOptions = {
  rejected?: boolean;
  resolveWith?: any;
};
const delay = (
  time: number,
  { rejected, resolveWith }: DelayOptions = {}
): Promise<void> => new Promise((res, rej) => {
  const timerId = setTimeout(() => {
    clearTimeout(timerId);
    const result = rejected ? rej : res;
    const resultArgs = [];
    if (resolveWith) {
      resultArgs.push(resolveWith);
    }
    result(...resultArgs);
  }, time);
});

const getParamsNames = (f: Function): string[] => {
  const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
  const ARGUMENT_NAMES = /([^\s,]+)/g;
  const fnStr = f.toString().replace(STRIP_COMMENTS, '');
  let result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
  if (result === null) result = [];
  return result;
};

const blobToObj = async (blob: Blob): Promise<any> => {
  const fileReader = new FileReader();
  const d = new Deferred();
  fileReader.onloadend = function loadEndResolver() {
    d.resolve(this.result);
  };
  fileReader.readAsText(blob);
  return JSON.parse(await d.promise);
};

const defineServerUrl = (target: 'REST' | 'WS'): string => {
  const { protocol: envProtocol, host: envHost } = window.location;
  let protocol;
  let route = IS_DEV ? '' : '/api';
  if (target === 'REST') {
    protocol = envProtocol;
  } else {
    protocol = IS_DEV ? 'ws:' : 'wss:';
    route += '/signaling/ws';
  }
  const host = IS_DEV ? envHost.replace(/:\d+/, `:${SERVER_PORT}`) : envHost;
  return `${protocol}//${host}${route}`;
};

const isMobile = (): boolean => ['Smartphone', 'Feature Phone', 'Other Mobile'].includes(WURFL.form_factor);

const isIPad = (): boolean => (
  (navigator.maxTouchPoints ?? 0) > 2
  && /MacIntel/.test(navigator.platform)
);

const isMobileOrTablet = (): boolean => (
  WURFL.form_factor !== 'Desktop' || isIPad()
);

const waitUntilBecomes = (
  observed: any,
  fieldName: string,
  desiredValue: any,
  resolveWith?: any,
): Promise<void> => new Promise(function observe(res) {
  const isWaitingBoolean = typeof desiredValue === 'boolean';
  const target = observed[fieldName];
  const checkedValue = isWaitingBoolean ? !!target : target;
  if (checkedValue === desiredValue) {
    res(resolveWith);
  } else {
    const timerId = setTimeout(() => {
      clearTimeout(timerId);
      observe(res);
    }, 200);
  }
});

const getAudioContext = (...args: any[]): AudioContext => {
  /** Яблокопроблемы */
  const AudioContextConstructor = window.AudioContext || (window as any).webkitAudioContext;
  return new AudioContextConstructor(...args);
};

export {
  delay,
  Deferred,
  getParamsNames,
  defineServerUrl,
  isMobile,
  isMobileOrTablet,
  blobToObj,
  waitUntilBecomes,
  getAudioContext
};
