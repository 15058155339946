export default {
  PATHS: {
    main: '/',
    connectingProvider: '/provider/connecting',
    connectedProvider: '/provider/connected',
    editorViewer: '/viewer/editor',
    studioViewer: '/viewer/studio',
    noConnection: '/noConnection',
  },
  PATHS_NAMES: {
    main: 'Main',
    connectingProvider: 'Connecting Provider',
    connectedProvider: 'Connected Provider',
    editorViewer: 'Editor Viewer',
    studioViewer: 'Studio Viewer',
    noConnection: 'No Connection',
  },
  VOLUME_FILTER_MODIFIER: 4,
  SERVER_RECONNECTION_PERIOD: 2_000,
  SERVER_RESPONSE_AWAIT_TIME: 5_000,
  ICE_CANDIDATES_GATHER_TIME_LIMIT: 5_000,
  NOTIFICATIONS_DISPLAY_TIME: 10_000,
  VIDEO_CODEC: {
    default: 'VP8',
    alt: 'H.264',
  },
  USED_DEVICES_STORAGE_KEY: 'jccDevices',
  PROVIDED_VIDEO_SETTINGS: {
    SD: {
      width: {
        min: 640,
        ideal: 640,
      },
      height: {
        min: 480,
        ideal: 480,
      },
      aspectRatio: 1.33333,
    },
    HD: {
      width: {
        min: 1280,
        ideal: 1280,
      },
      height: {
        min: 720,
        ideal: 720,
      },
      aspectRatio: 1.77777,
    },
    FullHD: {
      width: {
        min: 1280,
        ideal: 1920,
      },
      height: {
        min: 720,
        ideal: 1080,
      },
      aspectRatio: 1.77777,
    },
  },
};
